import {useEffect, useState} from "react";

export const useVisibility = () => {
    const [isTabActive, setIsTabActive] = useState(false);

    const onVisibilityUpdate = () => {
        if (document.visibilityState === 'hidden') {
            setIsTabActive(false);
        }

        if (document.visibilityState === 'visible') {
            setIsTabActive(true);
        }
    }

    const onMouseMove = () => {
        setIsTabActive(true)
        document.removeEventListener('mousemove', onMouseMove)
    }
    
    useEffect(() => {
        document.addEventListener('visibilitychange', onVisibilityUpdate);
        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityUpdate);
        }
    }, []);

    return { isTabActive };
}

export {}
